import * as firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBH82wLob39hRM48ekR3hynpOb7smTqQ80",
  authDomain: "arubapdvx.firebaseapp.com",
  databaseURL: "https://arubapdvx.firebaseio.com",
  projectId: "arubapdvx",
  storageBucket: "arubapdvx.appspot.com",
  messagingSenderId: "731428035740",
  appId: "1:731428035740:web:645e14cc176234d1962159"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export default firebase;
